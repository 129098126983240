/* global $ */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "highlighted", "unhighlighted" ]
  static targets = [ "idHighlight", "highlightedClass" ]
  static values = {
    controller: { type: String, default: "" },
    action: { type: String, default: "" },
    id: { type: String, default: "" },
  }

  ajax_submit_success_sidebar() {
    this.sidebar_close_and_remove_item()
  }

  ajax_submit_error_sidebar() {
    var [_data, _status, xhr] = event.detail;

    this.#update_sidebar_with(xhr.response)
    this.#sidebar_open()
  }

  ajax_success_sidebar() {
    var [_data, _status, xhr] = event.detail;

    this.#update_sidebar_with(xhr.response)
    this.#sidebar_open()
  }

  ajax_error_sidebar() {
    this.#update_sidebar_with("Error Failed to load")
  }

  sidebar_item_open() {
    const element = $("#" + this.idValue)

    element.removeClass(this.highlightedClass)
    element.removeClass(this.unhighlightedClass).addClass(this.unhighlightedClass)
    this.#sidebar_open()
  }

  sidebar_close_and_remove_item() {
    const element = $("#" + this.idValue)

    element.removeClass(this.highlightedClass)
    element.removeClass(this.unhighlightedClass).addClass(this.unhighlightedClass)

    this.#sidebar_close()
    this.#sidebar_item_remove()
  }

  #sidebar_content_name() {
    return this.controllerValue + "--" + this.actionValue
  }

  #sidebar_open() {
    $('.control-sidebar').ControlSidebar('show')
  }

  #sidebar_close() {
    $('.control-sidebar').ControlSidebar('collapse')
  }

  #update_sidebar_with(content) {
    this.#add_sidebar_content(content)
  }

  #add_sidebar_content(content) {
    this.#sidebar_item_add()
    $(".cs-content---" + this.#sidebar_content_name()).html(content)
  }

  #sidebar_loader_show() {
    $('#control-sidebar-loader').show()
  }

  #sidebar_loader_hide() {
    $('#control-sidebar-loader').hide()
  }

  #sidebar_item_query() {
    $(".cs-content---" +  this.#sidebar_content_name())
  }

  #sidebar_item_add() {
    $('.cs-content-default').hide()
    if ($('#control-sidebar-root').has(".cs-content---" + this.#sidebar_content_name()).length > 0) {
      $(".cs-content---" + this.#sidebar_content_name()).show()
    } else {
      $( "#control-sidebar-root" ).prepend( "<div class='cs-content---" + this.#sidebar_content_name() + "'></div>" );
    }
  }

  #sidebar_item_remove() {
    $(".cs-content---" + this.#sidebar_content_name()).remove()
  }
}
